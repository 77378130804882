import HireUsLogo from 'assets/images/HireUs512.webp';
const Logo = () => {
  return (
    <div>
      <img
        src={HireUsLogo}
        loading='lazy'
        className='w-16 h-16'
        alt='HireUs'
        width='64'
        height='64'
      />
    </div>
  );
};
export default Logo;
