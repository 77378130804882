export const HOME = '/';
export const EMPLOYERS = '/employers';
export const TALENTS = '/talents';
export const SHOP = '/shop';
export const JOBS = '/jobs';
export const EVENTS = '/events';
export const CONTACT = '/contact';
export const RESUME = '/resume';
export const ABOUT = '/aboutus';
export const REGISTER = '/signup';
export const REGISTERTALENT = '/signup/talent';
export const REGISTEREMPLOYER = '/signup/employer';
export const LOGIN = '/login';
export const FORGOTPASSWORD = '/forgot-password';
export const CHANGEPASSWORD = '/change-password';
export const TERMS = '/terms-of-service';
export const TALENT = '/talent';
export const EMPLOYER = '/employer';
export const ADMIN = '/admin';
