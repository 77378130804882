import { NavLink } from 'react-router-dom';
import UserLogin from 'assets/images/user_login.webp';

const CommonAuthLink = () => {
  return (
    <div className='pb-4 bg-white'>
      <div className='w-11/12 m-auto p-4 mt-4 container flex items-center justify-center md:hidden shadow-3xl rounded-full space-x-10'>
        <NavLink
          to='/login'
          className='px-3 py-2 rounded-md text-sm font-bold text-brand'
        >
          <img
            src={UserLogin}
            loading='lazy'
            className='h-12 w-12'
            alt='Login'
          />
        </NavLink>
        <NavLink to='/signup'>
          <span className='bg-brand rounded-full px-10 py-4'>
            <span className='text-white font-light text-base'>Sign Up</span>
          </span>
        </NavLink>
      </div>
    </div>
  );
};

export default CommonAuthLink;
