import { Link } from 'react-router-dom';
import * as routes from 'constants/Routes';
import AccessDeniedImg from 'assets/images/AccessDenied.svg';

export const AccessDenied = () => {
  return (
    <div className='flex items-center justify-center py-12'>
      <div className='flex items-center justify-center mx-4 md:w-2/3'>
        <div className='flex flex-col items-center py-16'>
          <img
            className='px-4 hidden md:block h-64 w-64'
            alt='Access Denied'
            src={AccessDeniedImg}
            loading='lazy'
          />
          <h1 className='px-4 pt-8 pb-4 text-center text-5xl font-bold leading-10 text-gray-800'>
            OOPS!
          </h1>
          <p className='px-4 pb-10 text-base leading-none text-center text-gray-600'>
            No signal here! we cannot find the page you are looking for
          </p>
          <Link to={routes.HOME}>
            <button className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-brand hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand'>
              Back to Dashboard
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
