import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import User from 'assets/images/user.webp';
import Notification from './Notification';
import * as routes from 'constants/Routes';

const AuthHeader = (): JSX.Element => {
  const authObj = useSelector((state: any) => state.auth);
  const { user: stateUser = {}, role, admin } = authObj;
  const [user, setUser] = useState({ name: '', avatar_url: '', org_name: '' });

  useEffect(() => {
    setUser(role === 'ADMIN' || role === 'admin' ? admin.user : stateUser);
  }, [user, admin, role, stateUser]);

  const [isMobile, setIsMobile] = useState<any>(null);

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    setIsMobile(
      toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      })
    );
  }, [isMobile]);

  return (
    <div className='hidden sm:flex justify-between items-center p-4 md:py-3 md:px-6 lg:px-8 sm:sticky sm:top-0 z-100 bg-white border-b border-gray-200'>
      <span className='flex flex-row'>
        <span className='text-lg md:text-sm text-gray-600 font-medium'>
          Welcome{' '}
          <span className='font-semibold text-brand-600 capitalize'>
            {user.name}!
          </span>{' '}
          {role === 'EMPLOYER' ||
            (role === 'employer' && (
              <span className='hidden lg:flex'>
                from
                <span className='capitalize pl-1'>{user.org_name}</span>
              </span>
            ))}
        </span>
      </span>
      <div className='flex items-center space-x-3'>
        {role === 'EMPLOYER' ||
          (role === 'employer' && (
            <NavLink to={`${routes.EMPLOYER}/post-job`}>
              <button className='bg-brand relative flex justify-center py-2 px-6 text-sm font-medium rounded-full text-white hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand'>
                Post a job
              </button>
            </NavLink>
          ))}
        {isMobile === false && (
          <Notification
            bgColor=''
            bellTextColor='text-gray-500'
            bellAnimtionColor='bg-brand-500'
          />
        )}
        <div className='flex text-sm focus:outline-none items-center space-x-1'>
          <NavLink
            to={`/${role}/settings`}
            className='flex text-sm focus:outline-none items-center space-x-1'
          >
            <img
              className='h-8 w-8 rounded-full p-1 ring-2 ring-gray-300'
              src={user.avatar_url || User}
              alt={user && user.name}
              loading='lazy'
            />
            <span className='text-xl md:text-sm text-black font-medium capitalize'>
              {user && user.name}
            </span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
export default AuthHeader;
