export const PublicNavigation = [
  {
    name: 'Resume',
    href: '/resume',
    icon: false,
    external: false,
    current: false,
    xs: true,
    button: false,
  },
  {
    name: 'Employers',
    href: '/employers',
    icon: false,
    external: false,
    current: false,
    xs: true,
    button: false,
  },
  {
    name: 'Talent',
    href: '/talents',
    icon: false,
    external: false,
    current: false,
    xs: true,
    button: false,
  },
  {
    name: 'Jobs',
    href: '/jobs',
    icon: false,
    external: false,
    current: false,
    xs: true,
    button: false,
  },
  // {
  //   name: 'Events',
  //   href: '/events',
  //   icon: false,
  //   external: false,
  //   current: false,
  //   xs: true,
  //   button: false,
  // },
  {
    name: 'About Us',
    href: '/aboutus',
    icon: false,
    external: false,
    current: false,
    xs: true,
    button: false,
  },
  {
    name: 'Contact',
    href: '/contact',
    icon: false,
    external: false,
    current: false,
    xs: true,
    button: false,
  },
  {
    name: 'Shop',
    href: '/shop',
    icon: false,
    external: false,
    current: false,
    xs: true,
    button: false,
  },
  {
    name: 'Login',
    href: '/login',
    icon: true,
    external: true,
    current: false,
    xs: false,
    button: false,
  },
  {
    name: 'Sign Up',
    href: '/signup',
    icon: false,
    external: true,
    current: false,
    xs: false,
    button: true,
  },
];
