import * as axios from 'axios';
import store from '../store';

let token = '';
function select(state: any) {
  const AUTH = state.auth;
  if (AUTH.token !== '') {
    return AUTH.token;
  }
}
function listener() {
  token = select(store.getState());
  return token;
}

const http = axios.default.create();
http.defaults.timeout = 300000;
http.defaults.withCredentials = true;
http.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};
http.interceptors.request.use(
  (config: any) => {
    store.subscribe(listener);
    if (token) {
      (config.headers ??= {}).authorization = token;
    }
    // Do something before request is sent
    return config;
  },
  (error: any) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
/**
 * intercepts any response and checks the response from our api for a 401 status in the response. ie. the token has now expired and is no longer valid, or no valid token was sent.
If such a status exists then we log out the user and clear the profile from redux state.
 */
http.interceptors.response.use(
  (res: any) => res,
  (err: any) => {
    if (err.response.data.status === 401) {
      if (err.response.data.message === 'SESSION_EXPIRED') {
        window.location.href = '/login';
      }
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
);

export default http;
