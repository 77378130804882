import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Logo from 'assets/images/HireUs512.webp';
import Key from 'assets/images/key.png';
import { Navigation } from 'constants/Navigation';
import {
  changeAdminViewAsync,
  userLogoutAsync,
} from 'redux/asyncActions/authAsyncActions';
import Spinner from './Spinner';

const Sidebar = () => {
  const location = useLocation();
  const authObj = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const { role, admin } = authObj;

  const [loader, setLoader] = useState(false);

  const logout = async () => {
    try {
      setLoader(true);
      setTimeout(() => {
        dispatch(userLogoutAsync());
        setLoader(false);
        localStorage.removeItem('location');
      }, 1000);
    } catch (err) {
      setLoader(false);
    }
  };
  const closeView = async () => {
    localStorage.removeItem('location');
    dispatch(changeAdminViewAsync(admin.user.id, false));
  };

  return (
    <div className='h-screen hidden md:flex md:flex-shrink-0 bg-white print:hidden border-r border-gray'>
      <div className='flex flex-col w-64'>
        <div className='flex flex-col flex-grow h-full'>
          <div className='flex items-center justify-between flex-shrink-0 pl-1 py-2 pr-3'>
            <NavLink
              className='flex items-center no-underline-all space-x-2 px-2'
              aria-label='Dashboard'
              to={`/${role}/jobs`}
            >
              <img
                className='h-12 w-auto'
                src={Logo}
                alt='HireUs'
                loading='lazy'
              />
              <span className='text-lg text-brand font-bold'>HireUs</span>
            </NavLink>
            {admin.isViewChanged === true ? (
              <div>
                <img
                  src={Key}
                  width='24'
                  height='24'
                  alt='Lock'
                  loading='lazy'
                />
              </div>
            ) : null}
          </div>
          <div className='mt-3 flex-1 flex flex-col overflow-y-auto'>
            <nav id='sidebar' className='flex-1'>
              <div className='pb-6 space-y-1 text-gray-400'>
                {role &&
                  Navigation &&
                  Navigation[role].map((item: any) => (
                    <NavLink
                      key={item.href}
                      to={`/${role}${item.href}`}
                      className={`${
                        location.pathname === `/${role}${item.href}`
                          ? 'text-brand bg-brand-50 border-l-4 border-brand px-3'
                          : 'text-black px-4'
                      }  group flex items-center py-2 text-sm no-underline-all font-semibold hover:text-brand hover:bg-gray-50`}
                      aria-current='page'
                    >
                      {item.icon}
                      <span className='ml-3'>{item.name}</span>
                    </NavLink>
                  ))}
              </div>
            </nav>
          </div>
          <div className='border-t border-gray'>
            <div className='flex-shrink-0 w-full group block no-underline-all px-4 py-2 hover:bg-grayest'>
              {admin.isViewChanged === false ? (
                <button
                  className='group relative flex items-center justify-center w-full p-3 bg-brand text-white font-medium rounded-full hover:bg-brand-700'
                  onClick={logout}
                >
                  {loader ? (
                    <div>
                      <span className='absolute left-0 inset-y-0 flex items-center pl-3 text-white'>
                        <Spinner />
                      </span>
                      <span className='tracking-wider'>Logging out...</span>
                    </div>
                  ) : (
                    <div>
                      <span className='tracking-wider'>Logout</span>
                    </div>
                  )}
                </button>
              ) : (
                <button
                  className='group relative flex items-center justify-center w-full p-3 bg-brand text-white font-medium rounded-full hover:bg-brand-700'
                  onClick={closeView}
                >
                  {loader ? (
                    <div>
                      <span className='absolute left-0 inset-y-0 flex items-center pl-3 text-white'>
                        <Spinner />
                      </span>
                      <span className='tracking-wider'>Closing...</span>
                    </div>
                  ) : (
                    <div>
                      <span className='tracking-wider'>Close View</span>
                    </div>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
