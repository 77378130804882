import { Navigate, useLocation } from 'react-router';

type PublicProps = {
  isAuthenticated: boolean;
  role: string;
  children: JSX.Element;
};

const PublicRoute = ({ isAuthenticated, role, children }: PublicProps) => {
  const location = useLocation();

  const url = role;
  if (isAuthenticated) {
    return <Navigate to={`/${url}`} state={{ from: location }} />;
  }

  return children;
};
export default PublicRoute;
