import { Navigate, useLocation } from 'react-router-dom';
import { AccessDenied } from 'pages/AccessDenied';

type PrivateProps = {
  isAuthenticated: boolean;
  role: string;
  allowedRole: string;
  children: JSX.Element;
};

const PrivateRoute = ({
  isAuthenticated,
  role,
  allowedRole,
  children,
}: PrivateProps) => {
  let location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to='/login' state={{ from: location }} />;
  }
  if (isAuthenticated && allowedRole !== role) {
    return <AccessDenied />;
  }

  return children;
};

export default PrivateRoute;
