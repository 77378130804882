/**
 * Application wide configuration.
 */
import {
  ADMINURL,
  AUTHBASEURL,
  EMPLOYERBASEURL,
  TALENTBASEURL,
} from "./baseUrl";
const config = {
  env: process.env.NODE_ENV,
  endpoints: {
    auth: {
      updateGlobalNotify: `${AUTHBASEURL}/v1/notification/clear`,
      getNotifications: `${AUTHBASEURL}/v1/notification/getAll`,
      readAllNotifications: `${AUTHBASEURL}/v1/notification/readAll`,
      forgotPassword: `${AUTHBASEURL}/v1/password/forgot`,
      changePassword: `${AUTHBASEURL}/v1/password/change`,
      resetPassword: `${AUTHBASEURL}/v1/password/reset`,
      verifyPassword: `${AUTHBASEURL}/v1/password/verify`,
      postResume: `${AUTHBASEURL}/v1/resume`,
      getAllResume: `${AUTHBASEURL}/v1/resume/all`,
      validateEmail: `${AUTHBASEURL}/v1/user/email`,
      updateValidateEmail: `${AUTHBASEURL}/v1/user/email/validate`,
      deleteAccount: `${AUTHBASEURL}/v1/user/delete-account`,
      changePrimaryAccount: `${AUTHBASEURL}/v1/user/change-account-holder`,
      getAllUsers: `${AUTHBASEURL}/v1/user/getAll`,
      login: `${AUTHBASEURL}/v1/user/login`,
      logout: `${AUTHBASEURL}/v1/user/logout`,
      refreshToken: `${AUTHBASEURL}/v1/user/refresh-token`,
      registerTalent: `${AUTHBASEURL}/v1/user/register/talent`,
      registerTalentWriteSea: `${AUTHBASEURL}/v1/user/register/talent/writesea`,
      registerEmployer: `${AUTHBASEURL}/v1/user/register/employer`,
      getResumeFile: `${AUTHBASEURL}/v1/user/resume`,
      uploadFile: `${AUTHBASEURL}/v1/upload`,
      uploadVideo: `${AUTHBASEURL}/v1/upload/video`,
      uploadEmployerFile: `${AUTHBASEURL}/v1/upload/employer`,
      getFile: `${AUTHBASEURL}/v1/upload/resume/drop-off`,
      deleteResumeDropOff: `${AUTHBASEURL}/v1/resume/delete`,
      getProfile: `${AUTHBASEURL}/v1/profile`,
      updateProfile: `${AUTHBASEURL}/v1/profile/update`,
      updateAbout: `${AUTHBASEURL}/v1/profile/update/about`,
      accountUpdate: `${AUTHBASEURL}/v1/user/account`,
      storeError: `${AUTHBASEURL}/v1/error/store`,
    },
    employer: {
      allOpenings: `${EMPLOYERBASEURL}/v1/job/all-openings`,
      myOpenings: `${EMPLOYERBASEURL}/v1/job/my-openings`,
      createJob: `${EMPLOYERBASEURL}/v1/job/create`,
      updateJob: `${EMPLOYERBASEURL}/v1/job/update`,
      showJob: `${EMPLOYERBASEURL}/v1/job/view`,
      poolOfTalent: `${EMPLOYERBASEURL}/v1/job/view/pool-of-talent`,
      rankedPoolOfTalent: `${EMPLOYERBASEURL}/v1/job/view/pool-of-talent/ranked`,
      updateJobStatus: `${EMPLOYERBASEURL}/v1/job/status`,
      startCandidate: `${EMPLOYERBASEURL}/v1/job/star-candidate`,
      updateJobApplication: `${EMPLOYERBASEURL}/v1/job/update-application-status`,
      getOrganization: `${EMPLOYERBASEURL}/v1/organization`,
      updateOrganization: `${EMPLOYERBASEURL}/v1/organization/update`,
      updateDiversity: `${EMPLOYERBASEURL}/v1/organization/update/diversity`,
      allUsers: `${EMPLOYERBASEURL}/v1/user/getAll`,
      addUser: `${EMPLOYERBASEURL}/v1/user/add`,
      getSubscription: `${AUTHBASEURL}/v1/subscription/getById`,
    },
    talent: {
      allOpenings: `${TALENTBASEURL}/v1/job/all-openings`,
      similerOpenings: `${TALENTBASEURL}/v1/job/similer-openings`,
      showJob: `${TALENTBASEURL}/v1/job/view`,
      applyJob: `${TALENTBASEURL}/v1/job/apply`,
      removeApplication: `${TALENTBASEURL}/v1/job/remove-application`,
      addBookmark: `${TALENTBASEURL}/v1/bookmark/add`,
      removeBookmark: `${TALENTBASEURL}/v1/bookmark/remove`,
      viewBookmark: `${TALENTBASEURL}/v1/bookmark/view`,
      viewAppliedJob: `${TALENTBASEURL}/v1/job/applied`,
    },
    admin: {
      allAdmins: `${ADMINURL}/v1/admin/getAll`,
      register: `${ADMINURL}/v1/admin/register`,
      getStats: `${ADMINURL}/v1/dashboard/stats`,
      showJob: `${ADMINURL}/v1/job/view`,
      submitFeedback: `${ADMINURL}/v1/support/submit-feedback`,
      getAllSupport: `${ADMINURL}/v1/support/getAll`,
      replyToTicket: `${ADMINURL}/v1/support/reply`,
      getEmployerById: `${ADMINURL}/v1/employer`,
      getAllEmployer: `${ADMINURL}/v1/employer/getAll`,
      getEmployerUserById: `${ADMINURL}/v1/employer/user`,
      getTalentById: `${ADMINURL}/v1/talent`,
      getAllTalent: `${ADMINURL}/v1/talent/getAll`,
      getAllSkills: `${ADMINURL}/v1/talent/getAllSkills`,
      changeView: `${AUTHBASEURL}/v1/user/change-view`,
      employerSubscription: `${AUTHBASEURL}/v1/subscription/update`,
      updateRank: `${ADMINURL}/v1/job/update-rank`,
    },
  },
  role: {
    ADMIN: `ADMIN`,
    TALENT: `TALENT`,
    EMPLOYER: `EMPLOYER`,
  },
  folderName: {
    RESUMES: `resumes`,
    AVATAR: `avatar`,
    EMPLOYER: `employer`,
    VIDEO: `video`,
  },
  fileType: {
    VIDEO: `video`,
    PDF: `pdf`,
    IMAGE: `image`,
  },
  GOOGLE_RECAPTCHA_SITE_KEY: "6LcQEgYgAAAAALNl9Ab0zyBQgaqXX4Apu9EwOXfZ",
};

export default config;
