import { NavLink, useLocation } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import Logo from 'assets/images/HireUs512.webp';
import UserLogin from 'assets/images/user_login.webp';
import { PublicNavigation } from 'constants/PublicNavigation';

const PublicHeader = () => {
  const location = useLocation();
  return (
    <Disclosure
      as='nav'
      className='md:max-w-[78rem] mx-auto px-0 sm:px-6 md:px-0 lg:px-8 xl:px-8 2xl:px-0 pt-4 md:pt-0 sm:py-0 z-100 relative'
    >
      {({ open }) => (
        <>
          <div className='relative flex items-center justify-between'>
            <div className='absolute inset-y-0 right-4 flex items-center lg:hidden'>
              {/* Mobile menu button*/}
              <Disclosure.Button className='inline-flex items-center justify-center p-2 text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none bg-brand-600 rounded-full shadow-3xl'>
                <span className='sr-only'>Open main menu</span>
                {open ? (
                  <XIcon
                    className='block h-8 w-8 text-white'
                    aria-hidden='true'
                  />
                ) : (
                  <MenuIcon
                    className='block h-8 w-8 text-white'
                    aria-hidden='true'
                  />
                )}
              </Disclosure.Button>
            </div>
            <div className='flex-1 flex items-start justify-start sm:items-stretch sm:justify-start'>
              <NavLink to='/'>
                <div className='flex items-center text-secondary text-xl sm:text-2xl font-bold pl-4 md:pl-8'>
                  <img
                    className='object-cover h-[3.56rem] w-[3.56rem]'
                    src={Logo}
                    alt='HireUs'
                    loading='lazy'
                  />
                  <span className='text-2xl md:text-[1.37rem]'>HireUs</span>
                </div>
              </NavLink>
            </div>
            <div className='absolute inset-y-2 right-0 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0 xl:ml-6 2xl:ml-0 rounded-full shadow-3xl bg-white h-20'>
              <div className='hidden lg:block lg:mx-4'>
                <div className='flex space-x-0 items-center'>
                  {PublicNavigation.map((item) => {
                    let linkComponent = (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={`font-bold ${
                          item.button
                            ? 'text-white bg-secondary border border-secondary rounded-full px-6 py-2.5 hover:text-secondary-600 hover:bg-white hover:border hover:border-secondary'
                            : 'text-brand px-[1rem] py-2'
                        }`}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.icon ? (
                          <img
                            src={UserLogin}
                            className='h-8 w-8'
                            alt='Login'
                            loading='lazy'
                          />
                        ) : item.button ? (
                          <span className='font-light text-base'>
                            {item.name}
                          </span>
                        ) : (
                          <span
                            className={`rounded-md text-sm2 font-bold hover:text-brand-600 ${
                              location.pathname === item.href
                                ? 'text-brand'
                                : 'text-secondary'
                            }`}
                          >
                            {item.name}
                          </span>
                        )}
                      </NavLink>
                    );
                    return linkComponent;
                  })}
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className='lg:hidden pt-2'>
            <div className='px-2 pt-2 pb-3 space-y-1 bg-secondary-50'>
              {PublicNavigation.map((item) => {
                let linkComponent = (
                  <Disclosure.Button
                    key={item.name}
                    as='a'
                    href={item.href}
                    className={`flex px-3 py-2 rounded-md text-sm font-medium
                    ${
                      location.pathname === item.href
                        ? 'text-brand'
                        : 'text-black'
                    }`}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                );
                return linkComponent;
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default PublicHeader;
