import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
dayjs.extend(isoWeek);

export const Date = {
  getWeekDates: () => {
    const startOfWeek = dayjs().startOf('w');
    const endOfWeek = dayjs().endOf('w');

    const days = [];
    let day = startOfWeek;

    while (day <= endOfWeek) {
      days.push(day.format('MM-DD-YYYY'));
      day = day.clone().add(1, 'd');
    }
    return days;
  },
  getMonthDates: () => {
    const startOfDay = dayjs().startOf('month');
    const endOfDay = dayjs().endOf('month');

    const days = [];
    let day = startOfDay;

    while (day <= endOfDay) {
      days.push(day.format('MM-DD-YYYY'));
      day = day.clone().add(1, 'd');
    }
    return days;
  },
  getYearMonths: () => {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  },
  fromNow: (date: string) => {
    return dayjs(date).fromNow();
  },
  format: (date: string, type: string) => {
    return dayjs(date).format(type);
  },
};
