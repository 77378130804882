export const VERIFY_TOKEN_STARTED = 'VERIFY_TOKEN_STARTED';
export const VERIFY_TOKEN_END = 'VERIFY_TOKEN_END';

export const USER_LOGIN_STARTED = 'USER_LOGIN_STARTED';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_CHILD_USER = 'UPDATE_CHILD_USER';
export const UPDATE_LAST_VISITED_ROUTE = 'UPDATE_LAST_VISITED_ROUTE';

export const CHANGE_ADMIN_VIEW = 'CHANGE_ADMIN_VIEW';
export const CHANGE_ADMIN_VIEW_START = 'CHANGE_ADMIN_VIEW_START';

export const UPDATE_GLOBAL_NOTIFY = 'UPDATE_GLOABL_NOTIFY';
