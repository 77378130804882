import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { verifyTokenAsync } from './redux/asyncActions/authAsyncActions';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AuthBase from './AuthBase';
import CommonAuthLink from 'components/CommonAuthLink';
import Footer from 'components/Footer';
import PublicHeader from 'components/PublicHeader';
import ScrollToTop from 'components/ScrollToTop';
import { Router } from './router/Router';

/**
 * Top level application router
 *
 * @returns {Component}
 */
const ROUTES = [
  '/',
  '/employers',
  '/shop',
  '/talents',
  '/jobs',
  '/events',
  '/aboutus',
  '/contact',
  '/resume',
];
export default function App(): React.ReactElement {
  const location = useLocation();
  const dispatch = useDispatch();
  const authObj = useSelector((state: any) => state.auth);
  const { role } = authObj;

  useEffect(() => {
    dispatch(verifyTokenAsync());
  }, [dispatch]);

  return (
    <HelmetProvider>
      <div>
        {ROUTES.includes(location.pathname) && (
          <div className='bg-white'>
            <PublicHeader />
            <CommonAuthLink />
          </div>
        )}
        {role !== null ? (
          <AuthBase />
        ) : (
          <ScrollToTop>
            <Router />
          </ScrollToTop>
        )}
        {ROUTES.includes(location.pathname) && (
          <div>
            <Footer />
          </div>
        )}
      </div>
    </HelmetProvider>
  );
}
