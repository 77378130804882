import Logo from 'components/Logo';

const ChangeView = () => {
  return (
    <div className='fixed inset-0 bg-white z-120'>
      <div className='h-screen flex justify-center items-center flex-col space-y-1 bg-white'>
        <Logo />
        <h5 className='font-semibold text-brand'>Please Wait....</h5>
        <span className='text-secondary font-semibold'>
          while we change view...
        </span>
      </div>
    </div>
  );
};
export default ChangeView;
