import { NavLink } from 'react-router-dom';
import Logo from 'assets/images/HUSCircleWhite.webp';

const Footer = () => {
  return (
    <div>
      <div className='bg-brand py-10 pb-20 relative'>
        <div className='lg:max-w-[70rem] xl:max-w-[70rem] mx-auto relative z-20 px-2 sm:px-6 lg:px-2'>
          <div className='grid md:grid-cols-3 lg:grid-cols-4 gap-4 items-center justify-center'>
            <div>
              <div className='flex items-center flex-col md:flex-row'>
                <img
                  className='object-fill'
                  src={Logo}
                  loading='lazy'
                  alt='HireUs'
                  width='64'
                  height='64'
                />
                <h1 className='font-bold text-white text-xl'>HireUs</h1>
              </div>
              <h1 className='text-sm md:text-base text-white pt-5 px-14 md:px-0 text-justify'>
                <span className='font-bold'>HireUs </span>
                <span>
                  is the ideal platform to hire diversity and for students of
                  diverse backgrounds to find jobs. Welcome to a world-class
                  hiring platform. Find. Match. Hire.
                </span>
              </h1>
            </div>
            <div className='flex flex-col items-center justify-center space-y-10 lg:col-span-2 pt-8 md:pt-0'>
              <NavLink to='/signup'>
                <button className='rounded-full bg-secondary hover:bg-secondary-600 text-white w-32 md:w-48 h-12'>
                  Sign Up
                </button>
              </NavLink>
              <NavLink to='/login'>
                <button className='rounded-full bg-white text-brand w-32 md:w-48 h-12'>
                  Login
                </button>
              </NavLink>
            </div>
            <div className='flex items-center justify-center md:items-start md:justify-end flex-col pt-8 md:pt-0'>
              <h1 className='font-bold text-white text-xl pb-4'>Get Started</h1>
              <div className='flex flex-col text-white space-y-2 items-center md:items-start'>
                <NavLink to='/employers'>Employers</NavLink>
                <NavLink to='/talents'>Talent</NavLink>
                <NavLink to='/jobs'>Jobs</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
