import * as routes from "constants/Routes";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";

import LazyLoader from "components/LazyLoader";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { useSelector } from "react-redux";

const Home = lazy(() => import("pages/Home"));
const Employers = lazy(() => import("pages/Landing/Employers"));
const Talents = lazy(() => import("pages/Landing/Talents"));
const Jobs = lazy(() => import("pages/Landing/Jobs"));
const Resume = lazy(() => import("pages/Landing/Resume"));
const Shop = lazy(() => import("pages/Landing/Shop"));
const Contact = lazy(() => import("pages/Landing/Contact"));
const About = lazy(() => import("pages/Landing/About"));
const Register = lazy(() => import("pages/Register"));
const Talent = lazy(() => import("pages/Register/Talent"));
const Employer = lazy(() => import("pages/Register/Employer"));
const WriteSea = lazy(() => import("pages/Register/WriteSea"));
const Login = lazy(() => import("pages/Login"));
const ForgotPass = lazy(() => import("pages/ForgotPass"));
const ChangePass = lazy(() => import("pages/ChangePass"));
const Terms = lazy(() => import("pages/Terms"));
const NotFound = lazy(() => import("pages/NotFound"));
const TalentDashboard = lazy(() => import("pages/Auth/Talent"));
const EmployerDashboard = lazy(() => import("pages/Auth/Employer"));
const AdminDashboard = lazy(() => import("pages/Auth/Admin"));

/**
 * Top level application router
 *
 * @returns {Component}
 */
const ROUTES = ["admin", "employer", "talent"];

export const Router = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const authObj = useSelector((state: any) => state.auth);
  const { isAuthenticated, role } = authObj;

  useEffect(() => {
    if (role !== null) {
      let url = role === "admin" ? `${role}/dashboard` : `${role}/jobs`;
      const getLastLocation = localStorage.getItem("location");
      if (getLastLocation && getLastLocation !== undefined) {
        url = getLastLocation;
      }
      navigate(url);
    }
    // eslint-disable-next-line
  }, [role]);

  useEffect(() => {
    const privateRoutes = location.pathname.split("/");
    if (
      role !== null &&
      location.pathname !== null &&
      ROUTES.includes(privateRoutes && privateRoutes[1])
    ) {
      localStorage.setItem("location", location.pathname);
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <Routes>
      <Route
        path={routes.HOME}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <Home />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.EMPLOYERS}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <Employers />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.SHOP}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <Shop />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.TALENTS}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <Talents />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.JOBS}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <Jobs />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.RESUME}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <Resume />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.CONTACT}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <Contact />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.ABOUT}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <About />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.REGISTER}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <Register />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.REGISTERTALENT}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <Talent />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={`${routes.REGISTERTALENT}/writesea`}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <WriteSea />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.REGISTEREMPLOYER}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <Employer />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.LOGIN}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <Login />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.FORGOTPASSWORD}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <ForgotPass />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.CHANGEPASSWORD}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <ChangePass />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.TERMS}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated} role={`${role}`}>
              <Terms />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={`${routes.TALENT}/*`}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              role={`${role}`}
              allowedRole="talent"
            >
              <TalentDashboard />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path={`${routes.EMPLOYER}/*`}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              role={`${role}`}
              allowedRole="employer"
            >
              <EmployerDashboard />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path={`${routes.ADMIN}/*`}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              role={`${role}`}
              allowedRole="admin"
            >
              <AdminDashboard />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<LazyLoader />}>
            <NotFound />
          </Suspense>
        }
      />
    </Routes>
  );
};
