import {
  BriefcaseIcon,
  UserGroupIcon,
  UserIcon,
  SupportIcon,
  ClipboardListIcon,
  OfficeBuildingIcon,
  HomeIcon,
  CogIcon,
  AcademicCapIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/outline';
export const Navigation: any = {
  talent: [
    {
      name: 'Jobs',
      href: '/jobs',
      icon: <BriefcaseIcon className='h-6 w-6' />,
    },
    {
      name: 'My Jobs',
      href: '/my-jobs',
      icon: <ClipboardListIcon className='h-6 w-6' />,
    },
    {
      name: 'Support Center',
      href: '/support-center',
      icon: <SupportIcon className='h-6 w-6' />,
    },
    {
      name: 'Settings',
      href: '/settings',
      icon: <CogIcon className='h-6 w-6' />,
    },
  ],
  employer: [
    {
      name: 'Jobs',
      href: '/jobs',
      icon: <BriefcaseIcon className='h-6 w-6' />,
    },
    {
      name: 'My Openings',
      href: '/my-openings',
      icon: <ClipboardListIcon className='h-6 w-6' />,
    },
    {
      name: 'Recruiting',
      href: '/recruiting',
      icon: <AcademicCapIcon className='h-6 w-6' />,
    },
    {
      name: 'Users',
      href: '/users',
      icon: <UserGroupIcon className='h-6 w-6' />,
    },
    {
      name: 'Organization Profile',
      href: '/organization-profile',
      icon: <OfficeBuildingIcon className='h-6 w-6' />,
    },
    {
      name: 'Support Center',
      href: '/support-center',
      icon: <SupportIcon className='h-6 w-6' />,
    },
    {
      name: 'Settings',
      href: '/settings',
      icon: <CogIcon className='h-6 w-6' />,
    },
  ],
  admin: [
    {
      name: 'Dashboard',
      href: '/dashboard',
      icon: <HomeIcon className='h-6 w-6' />,
    },
    {
      name: 'Jobs',
      href: '/jobs',
      icon: <BriefcaseIcon className='h-6 w-6' />,
    },
    {
      name: 'Resumes',
      href: '/resumes',
      icon: <DocumentDuplicateIcon className='h-6 w-6' />,
    },
    {
      name: 'Talent',
      href: '/talent',
      icon: <AcademicCapIcon className='h-6 w-6' />,
    },
    {
      name: 'Employers',
      href: '/employers',
      icon: <OfficeBuildingIcon className='h-6 w-6' />,
    },
    {
      name: 'Admins',
      href: '/admin-users',
      icon: <UserIcon className='h-6 w-6' />,
    },
    {
      name: 'Support Center',
      href: '/support-center',
      icon: <SupportIcon className='h-6 w-6' />,
    },
    {
      name: 'Settings',
      href: '/settings',
      icon: <CogIcon className='h-6 w-6' />,
    },
  ],
};
