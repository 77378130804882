import config from 'config';
import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { getWithExpiry, setWithExpiry } from 'storage';
import http from 'utils/http';

const ErrorFallback = ({ error }: { error: any }) => {
  const location = useLocation();

  const storeError = useCallback(
    async (message: any) => {
      const url = `${config.endpoints.auth.storeError}`;
      try {
        await http.post(url, { message, page: location.pathname });
      } catch (error) {}
    },
    [location.pathname]
  );
  // Handle failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    (async () => {
      await storeError(error?.message);
    })();
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }
    }
  }, [error, storeError]);

  return (
    <div className='flex h-screen flex-col items-center justify-center'>
      <div className='bg-black rounded-md shadow-md p-4 font-mono items-center justify-center flex flex-col'>
        <p className='text-white'>Something went wrong.</p>
        <pre className='text-red-500 font-semibold'>{error?.message}</pre>
      </div>
      <div className='flex items-center justify-center pt-4'>
        <button
          className='text-brand font-semibold text-sm font-sans'
          onClick={() => window.location.reload()}
          type='button'
        >
          Go Back
        </button>
      </div>
    </div>
  );
};
export default ErrorFallback;
