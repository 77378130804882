import { Menu, Transition } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/outline';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { updateGlobalNotify } from 'redux/actions/authActions';
import User from 'assets/images/user.webp';
import config from 'config';
import { Date } from 'helper/date';
import { INotification } from 'types';
import http from 'utils/http';

const Notification = ({
  bgColor,
  bellTextColor,
  bellAnimtionColor,
}: {
  bgColor: string;
  bellTextColor: string;
  bellAnimtionColor: string;
}) => {
  const dispatch = useDispatch();
  const authObj = useSelector((state: any) => state.auth);
  const { user: stateUser = {}, role, admin } = authObj;
  const user = role === 'ADMIN' || role === 'admin' ? admin.user : stateUser;

  const [notifications, setNotifications] = useState<INotification[] | []>([]);

  const getNotifications = useCallback(async () => {
    const url = config.endpoints.auth.getNotifications;
    try {
      const { data }: any = await http.get(url);
      setNotifications(data.notifications);
    } catch (err) {}
  }, []);

  useEffect(() => {
    (async () => {
      await getNotifications();
    })();
  }, [getNotifications]);
  const toggleNotification = async () => {
    try {
      const url = config.endpoints.auth.updateGlobalNotify;
      const { data }: any = await http.post(url, { user_id: user.id });
      setNotifications(data.notifications);
      dispatch(updateGlobalNotify({ data: false }));
    } catch (err) {
      dispatch(updateGlobalNotify({ data: true }));
    }
  };
  const readAll = async () => {
    const url = config.endpoints.auth.readAllNotifications;
    try {
      const { data }: any = await http.post(url);
      setNotifications(data.notifications);
    } catch (err) {}
  };

  return (
    <Menu as='div' className='ml-4 relative'>
      <div className='flex'>
        <Menu.Button
          className={`h-8 w-8 ${bgColor} max-w-xs rounded-full flex items-center justify-center text-sm focus:outline-none relative`}
        >
          <div onClick={toggleNotification}>
            <span className='sr-only'>Open user menu</span>
            {user && user.is_notified && (
              <span className='flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1'>
                <span
                  className={`animate-ping absolute inline-flex h-full w-full rounded-full ${bellAnimtionColor} opacity-75`}
                ></span>
                <span
                  className={`relative inline-flex rounded-full h-3 w-3 ${bellAnimtionColor}`}
                ></span>
              </span>
            )}
            <BellIcon className={`h-6 w-6 ${bellTextColor}`} />
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='origin-top-right absolute right-0 transition transform mt-4 w-86 sm:w-100 rounded-2xl shadow-lg bg-white focus:outline-none overflow-hidden z-50'>
          <Menu.Item>
            <div className='flex items-center justify-between py-3 px-4'>
              <h6 className='space-x-1 font-semibold'>
                <span className='text-sm'>You have</span>
                <span className='text-sm font-semibold text-brand'>
                  {notifications && notifications.length}
                </span>
                <span className='text-sm'>notifications</span>
              </h6>
            </div>
          </Menu.Item>
          <div className='h-72 overflow-y-auto'>
            {notifications.map((item, i) => {
              const details = (
                <div className='flex items-center gap-2 py-3 px-4'>
                  <div className='w-10/12 flex gap-1 '>
                    {item.is_read === false && (
                      <span className='self-start rounded-full h-2 w-2 bg-brand-600 pr-2'></span>
                    )}
                    <div className='flex flex-col'>
                      <div className='flex items-center justify-between'>
                        <strong className='text-brand-600 text-sm font-medium capitalize truncate'>
                          {item.notification_title}
                        </strong>
                      </div>
                      <span className='text-black text-xs font-medium capitalize'>
                        {item.notification_desc}
                      </span>
                      <span className='text-slate-400 text-xs font-light text-right truncate'>
                        {Date.fromNow(item.createdAt)}
                      </span>
                    </div>
                  </div>
                  <div className='w-2/12'>
                    <div className='relative px-2 flex justify-end'>
                      <img
                        className='w-8 h-8 rounded-full'
                        src={User}
                        alt={item.notification_title}
                        loading='lazy'
                      />
                    </div>
                  </div>
                </div>
              );
              return (
                <Menu.Item key={i}>
                  {item.redirect_url === null ? (
                    <div
                      className={`block text-sm text-gray-700 hover:bg-slate-100 hover:cursor-not-allowed ${
                        notifications.length === i + 1 ? '' : 'border-b'
                      }`}
                    >
                      {details}
                    </div>
                  ) : (
                    <NavLink
                      to={item.redirect_url}
                      className={`block text-sm text-gray-700 hover:bg-slate-100 hover:cursor-pointer ${
                        notifications.length === i + 1 ? '' : 'border-b'
                      }`}
                    >
                      {details}
                    </NavLink>
                  )}
                </Menu.Item>
              );
            })}
          </div>
          {notifications.length > 0 && (
            <Menu.Item>
              <div className='flex items-center justify-center border-t hover:bg-slate-100'>
                <button
                  className='p-3 text-secondary font-medium text-sm w-full'
                  onClick={readAll}
                >
                  Mark all read
                </button>
              </div>
            </Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default Notification;
