import ChangeView from 'components/ChangeView';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AuthHeader from './components/AuthHeader';
import MobileNavBar from './components/MobileNavBar';
import ScrollToTop from './components/ScrollToTop';
import Sidebar from './components/Sidebar';
import { Router } from './router/Router';

/**
 * Top level application router
 *
 * @returns {Component}
 */
export default function AuthBase(): React.ReactElement {
  const authObj = useSelector((state: any) => state.auth);
  const { isChangeViewStart } = authObj;

  const { innerWidth: width } = window;
  const [mobileNavBar, setMobileNavBar] = useState(false);

  useEffect(() => {
    if (width < 768) {
      setMobileNavBar(true);
    } else {
      setMobileNavBar(false);
    }
  }, [width]);

  return (
    <div>
      {mobileNavBar && <MobileNavBar />}
      <div className='hire-auth-base sm:h-screen flex overflow-hidden print:h-auto mt-20 sm:mt-0'>
        {!mobileNavBar && <Sidebar />}
        <div className='flex flex-col w-0 flex-1 overflow-hidden'>
          <main className='flex-1 relative overflow-y-auto overflow-x-hidden'>
            <div>
              {isChangeViewStart === false ? <AuthHeader /> : <ChangeView />}
              <ScrollToTop>
                <Router />
              </ScrollToTop>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
